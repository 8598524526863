.header {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}
.title {
    font-size: 20px;
    font-weight: 400;
    color: rgba(97, 97, 97, 1);
}