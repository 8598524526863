.wrapper {
    width: 100%;
    display: flex;
    background-color: var(--white);
    padding: var(--space-s);
  }
  
  .container {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 10px;
    overflow-y: scroll;
    max-height: calc(100vh - 100px);
  }
  
  .rightColumn {
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 10px;
    width: 50%;
  }
  
  .leftColumn {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 50%;
  }
  
  .div1,
  .div2,
  .div3,
  .div4,
  .div5 {
    border: 1px solid rgba(224, 224, 224, 1);
    padding: var(--space-s);
    background-color: var(--white);
    border-radius: 25px;
    height: max-content;
  }
  
  .container::-webkit-scrollbar {
    display: none;
  }
  
 
  @media screen and (max-width: 900px) {
 
    .container {
      display: flex;
      flex-direction: column;
      }
      .leftColumn,
      .rightColumn {
        display: flex;
        flex-direction: column;
        width: 100%;
      }
  }
  
  @media screen and (max-width: 768px) {
    .container {
    display: flex;
    flex-direction: column;
    }
  
    .leftColumn,
    .rightColumn {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  
    .div1,
    .div2,
    .div3,
    .div4,
    .div5 {
      height: auto; 
    }
  }
 