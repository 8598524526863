.title {
   font-size: 24px;
   color: var(--primary-color);
   font-weight: 500;
   margin-bottom: var(--space-s);
}
.descriptionText {
    max-height: 400px;
    overflow-y: scroll;
    padding-right: 5px;
}
.descriptionText::-webkit-scrollbar{
    width: 2px;
    background-color: #58585821;
}
.descriptionText::-webkit-scrollbar-thumb{
    width: 2px;
    background-color: #58585821;
}
.content {
    font-size: 12px;
    color: var(--primary-color);
    font-weight: 400;
}

.practice > span {
    background: rgb(233, 243, 246);
    font-weight: 500;
    font-size: 18px;
    color: var(--primary-color);
    padding: 10px;
    border-radius: 12px;
    cursor: pointer;
    transition: ease-in-out 0.2s;
}

.practice > span:hover {
    background: rgb(208, 238, 246);
    transition: ease-in-out 0.2s;
}

.practice {
    display: flex;
    flex-direction: row;
    gap: var(--space-xs);
}