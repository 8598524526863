.wrapper {
    border: solid 2px rgba(241, 249, 251, 1);
    border-radius: 18px;
    color: var(--primary-color);
    font-weight: 500;
    padding: 6px 6px;
    gap: 4px;
    display: flex;
    flex-direction: row-reverse;
    align-content: center;
    flex-wrap: wrap;
    align-items: center;
    cursor: pointer;
}
.wrapper > img {
    max-width: 40px;
}

.profileText {
    display: flex;
    gap: 4px;
}