.wrapper {
    display: block;
}
#search {
    padding: var(--space-s) var(--space-s);
    border-radius: 16px;
    border: 2px solid var(--primary-color);
}
.container{
    display: flex;
}

input {
    margin: 0;
    border-radius: 12px;
}

.inputWrap > input {
    padding: var(--space-s) var(--space-s);
    border-radius: 12px!important;
    border: 2px solid var(--primary-color);
}