.wrapper {
    margin: auto;
    position: fixed;
    left: 50%;
    top: 50%;
    height: 100%;
    z-index: 5;
    width: 100%;
    display: flex;
    transform: translate(-50%, -50%);
}
.modal::-webkit-scrollbar{
    width: 0;
}

.modal {
    max-height: 90%;
    overflow-y: scroll;
    max-width: max-content;
    background: var(--white);
    z-index: 100;
    margin: auto;
    position: relative;
    width: fit-content;
    border-radius: 16px;
    padding: var(--space-l) var(--space-xxl);
}