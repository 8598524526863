.container {
    display: block;
}

.header {
    border-radius: 16px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    color: var(--primary-color);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    margin-bottom: var(--space-s);
    font-size: 16px;
    padding: var(--space-xs) var(--space-s);
}

.list {
    display: flex;
    flex-direction: column;
    gap: var(--space-xs);
}