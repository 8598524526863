.container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--white);
    border-radius:8px;
    height: 100vh;
    padding: var(--space-xxl);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.008);
    overflow: scroll;
    margin: var(--space-xs);
  }
  
  .container::-webkit-scrollbar{
    display: none;
  }

  .inputGroupRow {
    display: flex;
    justify-content: space-between;
    gap: var(--space-xs);
  }
  .linkWrapper {
    width: 100%;
    margin-top: var(--space-s);
    text-align: center;
  }
.link{

  text-decoration: none;
  font-weight: 500;
  font-size: 20px;
  margin: 0 auto;
  text-align: center;
  color: var(--secondary-color);
}
  .inputGroupCol {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
