.menuItem {
    display: flex;
    align-items: top;
    text-decoration: none;
    color: var(--text-color);
    padding: var(--space-s) var(--space-xs);
    gap: 4px;
    border: solid 1px transparent;
    border-radius: 16px;
    transition: color 0.3s cubic-bezier(0.075, 0.82, 0.165, 1),
                background-color  0.2s ease-out,
                border 0.8s ease-in-out 0.6s;
}

.active {
    background-color: var(--accent-color);
    color: var(--white);
    border: solid 1px var(--accent-color);

}
.active > span > svg > path {
    background-color: var(--white);
    stroke: var(--white)!important;
}
.active.menuItem:first-child > span > svg > path {
    background-color: var(--white);
    fill: var(--white)!important;
    stroke: var(--white)!important;
}
.menuItem:hover {
    background-color: var(--accent-color);
    color: var(--white);
    border: solid 1px var(--accent-color);
}
.menuItem:first-child:hover > span > svg > path {
    background-color: var(--white);
    fill: var(--white)!important;
    stroke: var(--white)!important;
}
.menuItem:hover > span > svg > path {
    background-color: var(--white);
    stroke: var(--white)!important;
}
.icon {
    margin-right: 4px; 
}

.title {
    font-weight: 400;
}


@media screen and (max-width: 768px) {
    .menuItem {
        padding: var(--space-xs) var(--space-xs);
    }
    .icon {
        margin-right: 0;
    }
    .title {
        display: none;
    }
}