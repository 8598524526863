.inputWrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: var(--space-s);
  }
  
  .label {
    margin-bottom: var(--space-xs);
    font-size: var(--font-size-md);
    color: var(--color-text);
    
  }
  
  .input {
    padding: var(--space-xs);
    border: 1px solid transparent;
    border-radius: 8px;
    font-size: var(--font-size-md);
    color: var(--color-text);
    background-color: rgb(233 241 243);
    transition: border-color var(--transition-fast);
  }
  
  .input:focus {
    border-color: var(--color-primary);
    outline: none;
  }
  .errorMessage {
    font-size: 12px;
    color: red;
  }