.btn {
    background-color: var(--primary-color);
    color: var(--white);
    padding:var(--space-s)  var(--space-l);
    display: flex;
    width: max-content;
    border-radius: 16px;
    align-items: center;
    height: fit-content;
    cursor: pointer;
    transition: ease-in-out 0.2s;
}
.btn:hover {
    background-color: var(--primary-color-light);
    transition: ease-in-out 0.2s;
}
.btn >svg > path {
    fill: var(--white);
}
@media screen and (max-width: 768px) {
    .btn {
        padding: var(--space-s) var(--space-s);
    }
    .btn > span {
    display: none;
}
}

