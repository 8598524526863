.container {
    margin: 20px 0 0 0;
    height: fit-content;
    min-height: 280px;
}

.lables > div  {
    display: flex;
    flex-direction: column;
    ;
}
.lables > div > span {
    font-weight: 500
}

@media screen and (max-width: 768px) {
  
    
}