.noteContainer {
  position: relative;
  min-width: 250px;
  height: 100%;
  width: 100%;
}
.title {
   font-size: 24px;
   color: var(--primary-color);
   font-weight: 500;
   margin-bottom: var(--space-s);
}
.noteInput {
  width: 100%;
  height: 100px;
  border: none;
  outline: none;
  resize: none;
  font-size: 16px;
  padding: 10px;
  background-color: transparent;
  border-radius: 5px;
}

.noteInput.error {
  border-color: red;
}

.saveButton {
  background-color: #87ceeb;
  color: white;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  cursor: pointer;
  position: absolute;
  bottom: 20px;
  right: 20px;
  display: flex;
  align-items: center;
}

.saveButton img {
  margin-left: 5px;
}

.errorMessage {
  color: red;
  font-size: 14px;
  margin-top: 10px;
}
.paper {
  position: relative;
  width: 90%;
  max-width: 800px;
  min-width: 400px;
  height: 480px;
  margin: 0 auto;
  background: #fafafa;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0,0,0,.3);
  overflow: hidden;
}
.paper:before {
  content: '';
  position: absolute;
  top: 0; bottom: 0; left: 0;
  width: 60px;
  background: radial-gradient(#575450 6px, transparent 7px) repeat-y;
  background-size: 30px 30px;
  border-right: 3px solid #D44147;
  box-sizing: border-box;
}

.paper-content {
  position: absolute;
  top: 45px; right: 0; bottom: 30px; left: 60px;
  background: linear-gradient(transparent, transparent 28px, #91D1D3 28px);
  background-size: 30px 30px;
}

.paper-content textarea {
  width: 100%;
  max-width: 100%;
  height: 100%;
  margin-top: -15px;
  max-height: 100%;
  line-height: 30px;
  padding: 0 10px;
  border: 0;
  outline: 0;
  background: transparent;
  color: mediumblue;
  font-family: 'Handlee', cursive;
  font-weight: bold;
  font-size: 18px;
  box-sizing: border-box;
  z-index: 1;
}

@media screen and (max-width: 768px) {
 
 
 
  .paper{
    min-width: unset;
  }
  
}