.wrapper {
    background-color: var(--accent-color-light);
    border-radius: 24px;
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    padding: var(--space-m) var(--space-s);
    font-weight: 500;
}
@media screen and (max-width: 768px) {
    .wrapper {
        padding: var(--space-s) var(--space-xs);
        border-radius: 12px;
    }
}

.wrapper:hover {
    background: rgb(231, 229, 229);
    cursor: pointer;
    transition: ease 0.2s;
}
.score {
    display: flex;
    align-items: center;
    gap: var(--space-xs);
}