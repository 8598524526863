.phoneNumberContainer {
    width: 100%;
    margin: var(--space-xs) 0;
    transition: ease-in-out 0.3s;
  }
  .errorWrapper.show {
  height: 25px;
}
.errorWrapper {
  height: 0;
  overflow: hidden;
  transition: height 0.3s ease;
}
  .error {
  color: red;
  font-size: 0.8em;
  transition: ease-in-out 0.3s;
  margin-top: 5px;
}
  .phoneNumberInput {
    width: 100%;
    padding: var(--space-s);
    border: none;
    border-radius: 16px;
    outline: none;
    background: var(--accent-color-light);
    transition: ease-in-out 0.3s;
  }
  
  .phoneNumberInput::placeholder {
    color: var(--accent-color);
  }
  