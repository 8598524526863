.leader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
    padding: 15px;
    background-color: aliceblue;
    border-radius: 12px;
}

.leader > div {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}