/* AuthModal.module.css */
.container {
    display: flex;
    transition: ease 0.3s;
    height: max-content;
    width: fit-content;
}

.content {
    display: flex;
    border-radius: 36px;
    overflow: hidden;
}

.form {
    background-color: var(--white);
    padding: var(--space-m);
    transition: opacity 0.5s ease, transform 0.5s ease;
}

@media only screen and (max-width: 678px) {
    .content {
        flex-direction: column;
    }
}

.form-entering {
    opacity: 0;
    transform: translateX(-20px);
}

.form-entering-active {
    opacity: 1;
    transform: translateX(0);
}

.form-exiting {
    opacity: 1;
    transform: translateX(0);
}

.form-exiting-active {
    opacity: 0;
    transform: translateX(-20px);
}
