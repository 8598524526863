.submitButton {
    background-color: var(--primary-color);
     color: var(--white);
     padding: var(--space-xs) var(--space-s);
     font-size: var(--font-size-md);
     border: none;
     width: 100%;
     text-align: center;
     border-radius: 8px;
     cursor: pointer;
     transition: background-color var(--transition-medium);
     transition: 0.2s;
   }

   .container {
   background: white;
    padding: var(--space-m);
    border-radius: 12px;
  }
   
   .submitButton:hover {
     background-color: var(--primary-color-light);
   }

   .skip {
    background-color: var(--secondary-color);
     color: var(--white);
     padding: var(--space-xs) var(--space-s);
     font-size: var(--font-size-md);
     border: none;
     text-decoration: unset;
     text-align: center;
     width: 100%;
     display: block;
     margin: 10px 0;
     border-radius: 8px;
     cursor: pointer;
     transition: background-color var(--transition-medium);
     transition:ease 0.2s;
   }
   .skip:hover {
     background-color: var(--text-color);
     color: white;
   }