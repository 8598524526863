.layout {
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0;
    position: relative !important;
    width: 100%;
    overflow: hidden;
}

.body {
    display: flex;
    flex-direction: row;
    height: calc(100% - 75px);
}

.outlet {
    height: 100vh;
    background: #fff;
}

.sidebarToggle {
    display: none;
    position: absolute;
    top: 15px;
    left: 0;
    border-radius: 0 15px 15px 0;
    padding: var(--space-xs) var(--space-s);
    border: none;
    font-size: 24px;
    cursor: pointer;
    z-index: 55555;
    background: var(--accent-color);
}

@media (max-width: 768px) {
    .body {
        flex-direction: column;
        height: calc(100% - 75px);
    }

    .sidebarToggle {
        display: block;
    }
}
