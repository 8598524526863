.title {
   font-size: 24px;
   color: var(--primary-color);
   font-weight: 500;
   margin-bottom: var(--space-s);
}

.content {
    font-size: 12px;
    color: var(--primary-color);
    font-weight: 400;
}

.symptom {
    background: rgb(233, 243, 246);
    font-weight: 500;
    font-size: 18px;
    color: var(--primary-color);
    padding: 10px;
    border-radius: 12px;
    cursor: pointer;
    transition: ease-in-out 0.2s;
}

.symptom:hover {
    background: rgb(208, 238, 246);
    transition: ease-in-out 0.2s;
}

.practice {
    display: flex;
    flex-direction: row;
    gap: var(--space-xs);
}

.container {
    display: flex;
    flex-direction: row;
    gap: var(--space-xs);
    text-wrap: nowrap;
        flex-wrap: wrap;
}