.navbar {
    display: flex;
    flex-direction: column;
    gap: var(--space-s);
    padding: var(--space-s);
    min-width: 230px;
}

@media (max-width: 768px) {
    .navbar {
        width: 100%;
    }
}
