.wrapper {
    background-color: var(--accent-color-light);
    border-radius: 24px;
    display: flex;
    justify-content: space-between;
    padding: var(--space-m) var(--space-s);
    font-weight: 500;
}

.wrapper td {
    white-space: nowrap; 
    overflow-x: scroll;
    width: 10%;
    text-align: center;
}

@media only screen and (max-width: 768px) {
    .wrapper{
        height: 100%;
        display: flex !important;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }
    .wrapper td {
        width: 100%;
        height: 100%;
        width: 100%;
        height: 100%;
        display: flex !important;
        align-items: center;
        flex-direction: column;
        justify-content: space-evenly;
    }
}

.wrapper td::-webkit-scrollbar{
    display: none;
}