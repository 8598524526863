.selectType {
    position: absolute;
    top: 120%;
    left: 0%;
    width: 100%;
    background-color: var(--white);
    padding: var(--space-s);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    color: var(--text-color);
    z-index: 5;
}
.selectTypeOptions {
    display: flex;
    flex-direction: column;
    gap: var(--space-s);
}

.selectTypeOption:hover {
    background-color: var(--accent-color-light);
    border-radius: 8px;
    padding:var(--space-xs) var(--space-s);
    transition: ease-in-out 0.2s;
}

.selectTypeOption {
    transition: ease-in-out;
    padding: var(--space-xs) ;
    cursor: pointer;
    transition: ease-in-out 0.2s;
}