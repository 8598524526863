.container {
    display: block;
}
.loader {
    width: 100%;
    display: flex;
    height: 100%;
    align-items: center;
}

.list {
    display: flex;
    flex-direction: column;
    gap: var(--space-xs);
}