.wrapper span { 
    background:#fff; 
    padding:0 10px; 
    font-size: var(--font-size-base);
    color: var(--text-color); 
}
.wrapper {
    margin-top: var(--space-xl);
    width: 100%; 
    text-align: center; 
    border-bottom: 1px solid var(--text-color);; 
    line-height: 0.1em;
}