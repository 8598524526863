.wrapper {
    max-height: 100%;
    ;
    padding-bottom: var(--space-m);
    height: fit-content;
}
.header {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}
@media screen and (max-width: 1236px) {
    .header {
        display: flex;
       flex-direction: column;
       align-items: first baseline;
    }
    
}
.title {
    font-size: 20px;
    font-weight: 400;
    color: rgba(97, 97, 97, 1);
}
