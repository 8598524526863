
.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-size: cover;
    background-position: left;
    
}

.container {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    color: var(--primary-color);
}

.progressStep {
    margin-top: 20px;
    font-size: 1.2em;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}

.fadeIn {
    opacity: 1;
}
