.wrapper {
    padding: 20px;
    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .row {
    display: flex;
    flex-direction: row;
    gap: var(--spacing-s);
    align-items: center;
  }
  
  .form {
    display: flex;
    flex-direction: column;
  }
  
  .label {
    margin: 8px 0;
    font-size: 16px;
  }
  
  .input {
    padding: 16px;
    border-radius: 16px;
    border: 1px solid #ccc;
  }
  
  .submitButton {
    padding: 12px;
    width: 100%;
    cursor: pointer;
    border: solid 1px var(--green-color);
    border-radius: 16px;
    background-color: var(--green-color-light);
    color: var(--green-color);
    margin-left: 8px;
    display: flex;
    gap: var(--space-xs);
    align-items: center;
    width: 100%;
  }
  
  .cancelButton {
    background-color: #dc3545;
    color: #fff;
  }
  

  
.teams {
  display: flex;
  flex-wrap: wrap;
  margin: var(--space-s) 0;
  gap: var(--space-xs);
}

.team {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--space-xs);
  border-radius: 16px;
  padding: var(--space-xs) var(--space-s);
  background-color: var(--primary-color-ultra-light);
  cursor: pointer;
}

.team > span {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: var(--space-xs);
}
.team > div {
  display: contents;
}