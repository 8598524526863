.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999; 
  }
  
  @media screen and (min-width: 768px) {
    .backdrop {
      display: none;
    }
    
  }