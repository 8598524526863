h2 {
    color: var(--primary-color);
}
.row {
    display: flex;
    text-align: center;
    justify-content: center;
    gap: var(--space-s);
    transition: ease-in-out 0.2s;
    margin: 10px 0;
}
.row > div {
    width: 100%;
    display: flex;
    transition: ease-in-out 0.2s;
    flex-direction: column;
}

.errorText {
     transition: ease-in-out 0.2s;
    color: var(--red-color);
    font-size: 12px;
    text-align: left;
    margin-top: var(--space-s);
}
input {
    background: var(--accent-color-light);
    padding: 16px;
    border: none;
    width: 100%;
    border-radius: 12px;
    font-size: 14px;
    transition: ease-in-out 0.2s;
}
input:focus {
    outline: none;
}
input.error {
    border: 1px solid var(--red-color);
}
input.warning {
    border: 1px solid yellow;
}
input.success {
    border: 1px solid var(--green-color);
}
input::placeholder{
    color: rgba(53, 53, 53, 0.552);
}

button {
    background: var(--primary-color);
    padding:  var(--space-s)  var(--space-xl);
    border: none;
    border-radius: 12px;
    font-size: 14px;
    color: var(--white);
    cursor: pointer;
}