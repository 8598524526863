.container {
    display: flex;
    flex-direction: column;
    gap: var(--space-xs);
    text-align: center;
    padding: 0 var(--space-xl);
}

.title {
    font-size: 24px;
    font-weight: 500;
}