.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: var(--space-xs);
    border-bottom: solid 1px rgba(238, 238, 238, 1);
    background-color: var(--white);
}
.toggleButton {
  padding: var(--space-s);
  border: none;
  background-color: var(--primary-color);
  color: var(--white);
  border-radius: 4px;
  cursor: pointer;
}

.toggleButton:hover {
  background-color: var(--primary-color-light);
}
.sidebarToggle {
    display: none;
    background: none;
    border: none;
    font-size: 24px;
    margin: 0;
    background-color: var(--accent-color);
    padding: 0 var(--space-xs);
    height: 40px;
    cursor: pointer;
}
@media (max-width: 768px) {
    .sidebarToggle {
        display: block;
    }
}
.right, .left {
    display: flex;
    gap: var(--space-xs);
    align-items: center;
}