.wrapper {
    display: block;
}
#search {
    padding: var(--space-s) var(--space-s);
    border-radius: 16px;
    border: 2px solid var(--primary-color);
}
@media screen and (max-width: 768px) {
    #search {
        padding: var(--space-s) var(--space-xs);
        border-radius: 12px;
    }
}
.container{
    display: flex;
}