.btn {
    background-color: var(--green-color-light);
    border: solid 2px var(--green-color);
    color: var(--green-color);
    font-weight: 500;
    padding: 12px var(--space-l);
    display: flex;
    width: max-content;
    position: relative;
    border-radius: 16px;
    cursor: pointer;
    align-items: center;
}
.backdrop {
    width: 100%;
    height: 100%;
    position: fixed;
    right: 0;
    top: 0;
    background: rgb(97 97 97 / 32%);
    z-index: 1;
}
.wrapper {
    position: relative;
}
