.wrapper {
    display: block;
    height: 100%;
    background-color: var(--white);
    border-left: solid 1px var(--border-color-light);
    position: relative;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--space-l);
    margin-bottom: var(--space-l);
    border: solid 1px var(--border-color);
    border-radius: 36px;
    margin: var(--space-m);
    position: sticky;
    top: 0;
}
@media screen and (max-width: 768px) {
    .header {
        padding: var(--space-s);
        border-radius: 12px;
    }

}
.body {
    padding: var(--space-l) var(--space-m);
    margin-bottom: var(--space-l);
    border: solid 1px var(--border-color);
    border-radius: 36px;
    margin: var(--space-m);
    height: calc(100% - 200px);
    overflow-y: scroll;
    position: relative;
}
@media screen and (max-width: 768px) {
    .header {
        padding: var(--space-s);
        border-radius: 12px;
    }
    .body {
        padding: var(--space-s);
        border-radius: 12px;
    }
    
}
.scrollbar {
    position: absolute;
    top: 0;
    left: 0;
    width: 5px;
    height: 110%;
    background-color: #F5F5F5;
    border-radius: 15px;
    cursor: pointer;
}

#scrollbarThumb {
    width: 100%;
    background-color: var(--primary-color);
    border-radius: 15px;
    position: absolute;
    top: 0;
    height: 100%; 
}

.body::-webkit-scrollbar{
    display: none;
   
}

.left {
    display: flex;
    gap: var(--space-xs);
}