
  .background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: -1;
  }
  
  .svg-background {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover; 
  }
  
  .ball1,
  .ball2 {
    will-change: transform, opacity;
  }

  @keyframes wave {
  0% {
    transform: translateX(0) translateY(0);
  }
  50% {
    transform: translateX(20px) translateY(10px);
  }
  100% {
    transform: translateX(0) translateY(0);
  }
}

.wave-animation {
  width: 100vw;
  height: 100vh;
}

.wave-path {
  animation: wave 5s ease-in-out infinite;
  transform-origin: center;
}
  
.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw; /* Full width */
  height: 100vh; /* Full height */
  overflow: hidden; /* Hide overflow from paths */
  z-index: -1; /* Ensure background is behind other content */
}

.svg {
  width: 100%;
  height: 100%;
  display: block;
}
