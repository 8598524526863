.loader {
    width: 100%;
    display: flex;
    height: 100%;
    align-items: center;
  }
  thead{
    display: flow;
  }
  .table {
    width: 100%;
    border-collapse: collapse;
    table-layout: auto;
    overflow-x: auto;
    display: block;
  }
  .table::-webkit-scrollbar{
    display: none;
  }
  .container {
    display: block;
  }
  
  .header {
    border-radius: 16px;
    display: flex;
    justify-content: space-between;
    color: var(--primary-color);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    margin-bottom: var(--space-s);
    font-size: 16px;
    padding: var(--space-xs) var(--space-s);
  }
  
  .header th {
    white-space: nowrap;
    text-align: center;
    width: 10%;
  }
  
  .list {
    display: flex;
    flex-direction: column;
    gap: var(--space-xs);
  }
  .list::-webkit-scrollbar{
    display: none;
  }
  @media screen and (max-width: 768px) {
    .list {
        height: 100%;
        display: flex !important;
        flex-direction: row;
        padding-left: var(--space-xs);
    }
    .container {
        height: 100%;
      }
    .header {
      flex-direction: column;
      font-size: 14px;
      padding: var(--space-xs) var(--space-s);
    }
    thead {
        height: 100%;
    }
    .header th {
      text-align: left;
        width: 100%;
    }
    .header {
        height: 100%;
        display: flex!important;
        justify-content: space-around;
        align-items: center;
    }
  
    .table {
      display: block;
      overflow-x: auto;
      height: 100%;
    }
  
    .table thead,
    .table tbody,
    .table th,
    .table td,
    .table tr {
      display: block;
    }
  
    .table thead {
      float: left;
    }
  
    .table tbody {
      width: auto;
      position: relative;
      overflow-x: auto;
      white-space: nowrap;
    }
  
    .table tbody tr {
      display: inline-block;
      vertical-align: top;
    }
  }
  