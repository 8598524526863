.container {
    display: flex;
}
.input {
    width: 100%;
    padding: var(--space-s);
    border: 1px solid rgba(241, 249, 251, 1);
    background-color: rgba(241, 249, 251, 1);
    border-radius: 16px;
}
.inputWrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.input {
  padding-right: 30px; 
}

.eyeIcon {
  position: absolute;
  right: 10px;
  cursor: pointer;
  font-size: 1.2rem;
  color: #888;
}
