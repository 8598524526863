/* variables.css */
@import url(./styles.css);

*, *:before, *:after {
  box-sizing: border-box;
}
:root {
  /* Colors */
  --primary-color: rgba(54, 84, 134, 1);
  --primary-color-light: rgba(63, 95, 151, 0.821);
  --primary-color-ultra-light: rgba(227, 243, 247, 1);
  --secondary-color: rgba(250, 140, 108, 1);
  --secondary-color-light: rgba(255, 234, 227, 1);
  --accent-color: rgba(127, 199, 217, 1);
  --accent-color-light: rgba(241, 249, 251, 1);
  --red-color: rgba(176, 0, 32, 1);
  --red-color-light: rgba(176, 0, 32, 0.095);
  --green-color: rgba(102, 187, 106, 1);
  --green-color-light: rgba(182, 218, 132, 0.13);
  --border-color: rgba(224, 224, 224, 1);
  --background-color: #f4f4f4;
  --text-color: rgba(158, 158, 158, 1);
  --border-color: rgba(170, 218, 230, 1);
  --border-color-light: rgba(188, 213, 219, 0.221);
  --text-white: #fefefe;
  --white: #fefefe;
  
  /* Typography */
  --font-family: 'Helvetica Neue', sans-serif;
  --font-size-xs: 12px;
  --font-size-base: 14px;
  --font-size-m: 18px;
  --font-size-l: 20px;
  --font-size-xl: 24px;
  --font-size-xxl: 28px;
  --font-size-s: 14px;

  /* Spacing */
  --space-unit: 8px;
  --space-xs: var(--space-unit);
  --space-s: calc(2 * var(--space-unit));
  --space-m: calc(3 * var(--space-unit));
  --space-l: calc(4 * var(--space-unit));
  --space-xl: calc(5 * var(--space-unit));
  --space-xxl: calc(6 * var(--space-unit));
}

html.dark-mode {
  --primary-color: rgba(255, 255, 255, 0.87) !important;
  --primary-color-light: rgba(255, 255, 255, 0.6) !important;
  --primary-color-ultra-light: rgba(255, 255, 255, 0.3) !important;
  --secondary-color: rgba(255, 140, 108, 0.87) !important;
  --secondary-color-light: rgba(255, 234, 227, 0.6) !important;
  --accent-color: rgba(127, 199, 217, 0.87) !important;
  --accent-color-light: rgba(241, 249, 251, 0.3) !important;
  --red-color: rgba(255, 0, 0, 0.87) !important;
  --red-color-light: rgba(255, 0, 0, 0.1) !important;
  --green-color: rgba(102, 187, 106, 0.87) !important;
  --green-color-light: rgba(182, 218, 132, 0.13) !important;
  --border-color: rgba(128, 128, 128, 0.3) !important;
  --background-color: #121212 !important;
  --text-color: rgba(255, 255, 255, 0.87) !important;
  --border-color: rgba(128, 128, 128, 0.5) !important;
  --border-color-light: rgba(128, 128, 128, 0.1) !important;
  --text-white: #121212 !important;
  --white:#121212f5 !important; /* Changed to a dark color */
}
