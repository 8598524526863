.box {
    border: 1px solid rgba(127, 199, 217, 1);
    border-radius: 32px;
    padding: var(--space-m);
    min-width: 32%;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }
  
  .center{
    width: 100%;  
      display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
  }

  .NoteBox {
    min-width: 50%;
  }
  
  .loader {
    width: 100%;
    display: flex;
    height: 100%;
    align-items: center;
  }
  
  .container {
    padding: var(--space-m);
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: var(--space-s);
  }
  
  .row {
    display: flex;
    width: 100%;
    gap: var(--space-s);
  }
  
  .back {
    text-align: center;
    margin-top: var(--space-s);
  }
  
  @media screen and (max-width: 768px) {
    .container {
        overflow: scroll;
    }
    
    .container::-webkit-scrollbar {
      display: none;
    }
    .row {
      flex-direction: column;
      gap: var(--space-s);
    }
  
    .box {
      min-width: 100%;
    }
  
    .NoteBox {
      min-width: 100%;
    }
  }
  
  @media screen and (max-width: 480px) {
    .row {
      flex-direction: column;
      gap: var(--space-s);
    }
  
    .box {
      min-width: 100%;
      padding: var(--space-s);
    }
  
    .NoteBox {
      min-width: 100%;
      padding: var(--space-s);
    }
  }
  