.labels {
    display: flex;
    justify-content: space-between;
    font-weight: 500;
    font-size: large;
    margin-top: 10px;
    gap: 20px;
}

.labels > div > span {
    display: flex;
    flex-direction: column;
    font-weight: 800;
    font-size: large;
    margin-top: 10px;
    color: var(--primary-color);
}
.labels > div > span > span {
    font-weight: 400;
    font-size: large;
    margin-top: 10px;
    gap: 20px;
    color: rgba(158, 158, 158, 1);
}

@media screen and (max-width: 768px) {
.labels{
    display: flex;
    justify-content: space-between;
    font-weight: 500;
    font-size: medium;
    margin-top: 0px;
    gap: 12px;
}
.labels > div > span > span {
    font-size: small;
    margin-top: 5px;
    gap: 15px;
}
    
}