.container {
    display: flex;
    flex-direction: column;
    gap: var(--space-xs);
    text-align: center;
    padding: 0 var(--space-xl);
}

.mood {
    font-size: 14px;
    font-weight: 500;
    color: var(--primary-color);
}