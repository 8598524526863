select {
    padding: var(--space-s);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    border: none;
}

select:focus-visible {
    box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, 0.1)!important;
    border: none!important;
}