.wrapper {
  padding: 20px;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.row {
  display: flex;
  flex-direction: row;
  gap: var(--spacing-s);
  align-items: center;
}

.form {
  display: flex;
  flex-direction: column;
}

.label {
  margin-bottom: 8px;
  font-size: 16px;
}

.input {
  padding: var(--space-s);
  border-radius: 16px;
  border: 1px solid #ccc;
}

.submitButton {
  padding:  12px;
  cursor: pointer;
  border: solid 1px var(--green-color);
  border-radius: 16px;
  background-color: var(--green-color-light);
  color: var(--green-color);
  margin-left: 8px;
  display: flex;
  width: 100%;
  gap: var(--space-xs);
  justify-content: center;
  align-items: center;
  align-items: center;
}

.cancelButton {
  background-color: #dc3545;
  color: #fff;
}

.departments {
  display: flex;
  flex-wrap: wrap;
  margin: var(--space-s) 0;
  gap: var(--space-xs);
}

.department {
  border-radius: 16px;
  padding: var(--space-xs) var(--space-s);
  background-color: var(--primary-color-ultra-light);
}

.department > span {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: var(--space-xs);
}
.department > span > div {
  display: contents;
}