.container {
    border-right: solid 1px rgba(238, 238, 238, 1);
    transition: transform 0.3s ease;
    background-color: var(--white);
}

@media (max-width: 768px) {
    .container {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 250px;
        transform: translateX(-100%);
        z-index: 1000;
        background-color: white;
    }

    .container.open {
        transform: translateX(0);
    }
}

.content {
    padding: var(--space-m);
}