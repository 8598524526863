.otp-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 1rem;
  }
  
  .otp-inputs {
    display: flex;
    justify-content: center;
  }
  
  .otp-input {
    width: 50px;
    height: 50px;
    margin: 0 10px;
    text-align: center;
    font-size: 24px;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
    transition: border-color 0.3s;
  }
  
  .otp-input:focus {
    border-color: #007bff;
  }
  
  .success-message {
    color: green;
    margin-top: 10px;
    font-size: 16px;
  }
  
  .error-message {
    color: red;
    margin-top: 10px;
    font-size: 16px;
  }
  