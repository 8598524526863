
.svgContainer {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  
  position: relative;
}
.backdrop {
 position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #0000000a;
}
select {
      min-width: 100%;
}


.modal {
  position: absolute;
  min-width: 300px;
  right: 100%;
  top: 0;
  z-index: 2;
  padding: var(--space-s);
  background-color: white;
  box-shadow: 0px 0px 10px 0px #00000036;
  border-radius: 12px;
}
.svg {
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.toggled {
  transform: rotate(540deg);
}

.path {
  transition: d 0.3s ease;
}
