.profileImage {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .profileImage img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
    cursor: pointer;
    overflow: visible;
  }
  
  .title {
    color: var(--accent-color);
    font-weight: 500;
  }

  .imageInput {
    display: none;
  }
  