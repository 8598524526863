
.title {
    font-weight: 500;
    font-size: var(--text-m);
}

.container {
    padding: var(--space-m);
}

.buttons {
    margin-top: var(--space-m);
    gap: 5px;
    display: flex;
}
.container {
    background: white;
     padding: var(--space-m);
     border-radius: 12px;
   }
.submitButton {
    background-color: var(--primary-color);
     color: var(--white);
     padding: var(--space-xs) var(--space-s);
     font-size: var(--font-size-md);
     border: none;
     text-align: center;
     border-radius: 8px;
     cursor: pointer;
     transition: background-color var(--transition-medium);
     transition: 0.2s;
   }
   
   .submitButton:hover {
     background-color: var(--primary-color-light);
   }

   .resendButton {
    background-color: var(--primary-color);
     color: var(--white);
     padding: var(--space-xs) var(--space-s);
     font-size: var(--font-size-md);
     border: none;
     text-align: center;
     border-radius: 8px;
     cursor: pointer;
     transition: background-color var(--transition-medium);
     transition: 0.2s;
   }
   
   .resendButton:hover {
     background-color: var(--primary-color-light);
   }