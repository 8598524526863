.bell {
    padding:10px;
    background-color: var(--accent-color-light);
    border-radius: 8px;
    cursor: pointer;
    height: max-content;
    transition: ease 0.2s;
}
.bell:hover {
    padding:10px;
    background-color: var(--primary-color-ultra-light);
    border-radius: 8px;
    cursor: pointer;
    height: max-content;
}