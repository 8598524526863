.box > h1 {
    font-size: 24px;
    font-weight: 500;
    color: var(--primary-color);
}

.success {
    color: var(--green-color);
}

.error {
    color: var(--red-color);
}
.inputBox {
  position: relative;
  margin-bottom: 20px;
}

.labelBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.eyeIcon {
  cursor: pointer;
  font-size: 1.2rem;
  color: #888;
  margin-left: 10px;
}

.form > div > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: var(--space-xs);

}


button {
    transition: all 0.3s;
    border: none;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 400;
    background-color: var(--primary-color);
    color: var(--white);
    cursor: pointer;
    padding: 4px var(--space-xs);
}

.submit {
    width: 100%;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 400;
    background-color: var(--primary-color);
    color: white;
    cursor: pointer;

}

.box > p {
    font-size: 16px;
    font-weight: 400;
    margin-top: 10px;
}

.box > form {
    margin-top: 20px;
}

.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.box {
    width: 400px;
    padding: 20px;
    border-radius: 16px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: var(--white);
}

.box > form > div {
    margin-top: 20px;
}

.box > form > div > label {
    font-size: 16px;
    font-weight: 500;
    color: var(--primary-color);
}

.box > form > div > input {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: var(--white);
    border-radius: 8px;
    font-size: 16px;
    font-weight: 400;
}

.submit {
    width: 100%;
    padding: 10px;
    margin-top: 20px;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 400;
    background-color: var(--primary-color);
    color: white;
    cursor: pointer;
}

.submit:hover {
    background-color: var(--primary-color-light);
}