.container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--white);
    border-radius:36px;
    padding: var(--space-xxl);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
  .errorText {
    transition: ease-in-out 0.2s;
   color: var(--red-color);
   font-size: 12px;
   text-align: left;

}
  .inputGroupRow {
    display: flex;
    justify-content: space-between;
    gap: var(--space-xs);
  }

  .inputGroupCol {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
