.container {
 margin: var(--space-l) auto;
 text-align: center;
 display: flex;
flex-direction: column;
justify-content: center;
gap: var(--space-s);
}

.img {
    max-width: 200px;
    margin: 0 auto;
}

.title {
    font-size: 16px;
}

.btn {
   background: var(--secondary-color);
   padding: 12px 50px;
   margin: 0 auto;
   border-radius: 16px;
   width: fit-content;
   opacity: 0px;
   color: white;
   cursor: pointer;
   transition: ease 0.3s;
}

.btn:hover {
    background: rgb(243, 112, 73);
}