.department {
  margin: 10px 0;
}
.name > div:last-child{
  font-weight: 500;
  font-size: 18px;
}
.name > div:first-child{
  font-size: 14px;
}
.department-header {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: rgba(216, 238, 243, 1);
  border-radius: 16px;
  cursor: pointer;
  position: relative;
}

@media (max-width: 768px) {
  .department-header {
    border-radius: 12px;
  }
}

.department-name {
  flex: 1;
  display: flex;
  align-items: center;
  gap: var(--space-xs);
}

.edit-icon, .delete-icon {
  margin-left: 5px;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s;
  position: absolute;
  right: 5px;
}

.department-header:hover .edit-icon,
.department-header:hover .delete-icon {
  opacity: 1;
  display: contents;
}

.sections {
  padding-left: 20px;
  transition: max-height 0.3s ease-out;
  overflow: hidden;
}

.sections.collapsed {
  max-height: 0;
}

.num {
  font-size: 12px;
  color: white;
  border-radius: 4px;
  padding: 0 10px;
  height: 10px;
  margin-left: 5px;
  background-color: rgba(97, 97, 97, 0.625);
}
