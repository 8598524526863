.wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    min-width: 50vw;
    box-shadow: 0px 0px 15px 1px #0000001f;
    border-radius: 15px;
    padding: var(--space-m);

}

.col > div > span {
    width: 100%;
}
.col > div {
    display: flex;
    align-items: center;
    flex-direction: column-reverse;
    gap: var(--space-xs)
}
.chart {
    text-align: center;
}

.chart  > div {
    margin: 0 auto;
}
.row {
    display: flex;
    flex-direction: row;
    margin-bottom: var(--space-s);
    gap: 30px;
}

.col {
    width: 50%;
}

@media only screen and (max-width: 768px) {
    .row {
        display: flex;
        flex-direction: column;
        margin-bottom: var(--space-s);
        gap: 30px;
    } 
    .wrapper {
        width: 90%;
    }
    .col {
        width: 90%;
        margin: 0 auto;
    }
}