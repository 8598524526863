
.wrapper > div {
    display: flex;
    margin-top: var(--space-xs);
    align-items: center;
    flex-direction: column;
    gap: var(--space-xs);
    
}

.wrapper {
    width: 100%;
}