.field-wrapper {
  display: flex;
  flex-direction: column;
  transition: ease-in-out 0.3s;
  gap: 5px;
}

.error-wrapper {
  height: 0;
  overflow: hidden;
  transition: height 0.3s ease;
}

.error-wrapper.show {
  height: 20px;
}

.error {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

.row{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  gap: 10px;
}
.logo {
  margin: 0 auto;
  text-align: center;

}

.title {
  font-size: var(--font-size-xl);
  color: var(--primary-color);
  margin-bottom: var(--space-m);
  text-align: center;
}

.alternative {
 font-size: var(--font-size-base);
 color: var(--text-color);
 margin-top: var(--space-s);
 text-align: center;
}

.alternative > a{
  font-size: var(--font-size-base);
  color: var(--accent-color);
  text-decoration: none;
  margin-left: 5px;
}

.forgot {
  font-size: var(--font-size-xs);
  color: var(--text-color);
  margin-top: var(--space-s);
  text-align: right;
}