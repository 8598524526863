.wrapper {
    max-height: 100%;
}
.header {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

.more {
    color: var(--accent-color);
    display: flex;
    align-items: flex-end;
    justify-content: center;
    font-weight: 500;
    cursor: pointer;
}

.title {
    font-size: 20px;
    font-weight: 400;
    color: rgba(97, 97, 97, 1);
}

.content {
    display: flex;
    flex-direction: column;
    gap: var(--space-xs);
}
.content > div {
    overflow-y: scroll;
    max-height: 400px;  
}

.content > div::-webkit-scrollbar {
    width: 5px;
}
