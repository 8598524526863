.wrapper {
    background: rgba(250, 250, 250, 1);
    padding: var(--space-xs) var(--space-s);
    border-radius: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    transition: ease 0.2s;
}
.wrapper:hover {
    background: rgb(231, 229, 229);
    cursor: pointer;
    transition: ease 0.2s;
}
.name {
    color: var(--primary-color);
    font-size: 12px;
    font-weight: 400;
}

.report {
    color: rgba(97, 97, 97, 1);
    font-size: 12px;
    font-weight: 400;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: stretch;
}