.inputFieldContainer {
  margin: var(--space-xs) 0 ;
  margin-top: 5px;
}

.inputField {
  width: 100%;
  padding: var(--space-s);
  border: none;
  border-radius: 16px;
  outline: none;
  background: var(--accent-color-light);
}

.inputField::placeholder {
  color: var(--accent-color);
}

.error {
  color: red;
  font-size: 0.8em;
  margin-top: 5px;
}
.error-wrapper.show {
  height: 20px;
}
.error-wrapper {
  height: 0;
  overflow: hidden;
  transition: height 0.3s ease;
}