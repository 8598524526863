
.header {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
} 

.actives {
    display: flex;
    align-items: flex-start;
    justify-content: left;
    gap: 4px;
    color: rgba(97, 97, 97, 1);
}

.modal {
    background-color: #00000014;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.toggle {
    cursor: pointer;
}

.ProgressBar {
    display: flex;
    flex-direction: column;
    gap: var(--space-s)
}
.ProgressBar > div {
    display: flex;
    align-items: center;
    flex-direction: column-reverse;
    gap: var(--space-xs)
}
.ProgressBarWrapper {
    display: flex;
    flex-direction: column;
    gap: var(--space-s) ;
    width: 100%; 
}
.title {
    font-size: 20px;
    font-weight: 400;
    color: rgba(97, 97, 97, 1);
}

.container {
    padding: var(--space-s);
}

.title {
    font-size: 20px;
    font-weight: 400;
    color: rgba(97, 97, 97, 1);
}

.body {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;    flex-direction: row;
}
 @media screen and (max-width: 1236px) {
    .body{
        flex-direction: column;
    }
    .ProgressBar {
        width: 100%;
    }
    .detail {
        width: 100%!important;
    }
  }
.detailHeader {
    display: flex;
    align-items: center;
    gap: 3px;
    justify-content: center;
    width: 100%;
}

.header {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

.detail {
    width: 50%;
}